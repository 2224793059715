<template>
  <div class="formSection">
    <fieldset class="rounded p-3 card">
      <h5 class="text-center fw-bolder">{{ sectionName }}</h5>
      <div class="mb-3">
        <label for="endDate" class="form-label"
          >* Día de Ejecución de la Actividad</label
        >
        <input
          type="date"
          class="form-control"
          name="endDate"
          v-model="endDate"
          required
        />
        <div class="invalid-feedback">
          Por favor ingrese un día
        </div>
      </div>
      <div class="mb-3">
        <label for="startTime" class="form-label"
          >* Hora de Inicio de la Actividad</label
        >
        <input
          type="time"
          class="form-control"
          name="startTime"
          v-model="startTime"
          required
        />
        <div class="invalid-feedback">
          Por favor ingrese una hora
        </div>
      </div>
      <div class="mb-3">
        <label for="endTime" class="form-label"
          >* Hora de Finalización de la Actividad</label
        >
        <input
          type="time"
          class="form-control"
          name="endTime"
          v-model="endTime"
          required
        />
        <div class="invalid-feedback">
          Por favor ingrese una hora
        </div>
      </div>
      <div class="d-flex justify-content-end">
        <button
          class="btn btn-secondary shadow-secondary"
          @click.prevent="next('forward')"
        >
          Siguiente
        </button>
      </div>
    </fieldset>
  </div>
</template>

<script>
import Storage from "@/modules/storage";

const store = new Storage();

export default {
  props: [
    "branchID",
    "sectionName",
    "defaultData",
    "sectionPosition",
    "submitValidate",
    "submitName"
  ],
  data() {
    return {
      initDefault: true,
      mounted: false,
      employeeList: [],
      endDate: "",
      startTime: "",
      endTime: "",
      errorMsg: ""
    };
  },
  methods: {
    submit() {
      this.$emit("submitForm", true);
    },
    next(event) {
      this.$emit("moveForm", event);
    }
  },
  watch: {
    defaultData: function(val) {
      if (this.initDefault) {
        const defaultInterval = setInterval(() => {
          if (this.mounted) {
            clearInterval(defaultInterval);
            if (val.registrant !== undefined && val.registrant !== null) {
              this.registrantSelected = val.registrant;
            }
            if (val.responsable !== undefined && val.responsable !== null) {
              this.responsableSelected = val.responsable;
            }
          }
        }, 10);
        this.initDefault = false;
      }
    },
    submitValidate: function(val) {
      if (this.responsableSelected === "") {
        this.errorMsg = "Por favor seleccione un empleado";
      } else {
        this.errorMsg = "";
      }

      if (this.errorMsg !== "") {
        this.$emit("moveForm", this.sectionPosition);
      }
    }
  },
  name: "Summary Form Execute"
};
</script>

<style lang="css" scoped>
.formSection {
  min-width: 100vw;
}
.formSection fieldset {
  width: 350px;
  background-color: var(--secondary-bg);
  margin: auto;
}
</style>
