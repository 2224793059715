<template>
  <div>
    <passContainer
      :sections="sections"
      :activeSection="activeSection"
      @moveForm="moveForm($event)"
    ></passContainer>
    <titleForm
      title="Ejecutando la"
      strong="Actividad del Plan de Mejora"
    ></titleForm>
    <form
      id="planForm"
      class="mainForm mb-3 needs-validation"
      v-bind:class="{ 'was-validated': showValidate }"
    >
      <div class="xScroll d-flex">
        <first
          :branchID="branchID"
          :submitValidate="submitStart"
          :sectionName="sections[0]"
          sectionPosition="0"
          @moveForm="moveForm($event)"
        ></first>
        <second
          :submitValidate="submitStart"
          :sectionName="sections[1]"
          sectionPosition="1"
          submitName="Ejecutar Actividad"
          @moveForm="moveForm($event)"
          @submitForm="submit()"
        ></second>
      </div>
    </form>
    <Legal></Legal>
  </div>
</template>

<script>
import Storage from "@/modules/storage";
import { validateFormData } from "@/modules/tools";
import Swal from "sweetalert2";

import passContainer from "@/components/form/passContainer";
import titleForm from "@/components/form/Title";
import Legal from "@/components/layout/Legal";

import first from "@/views/plain/form/summary/Execute";
import second from "@/views/plain/form/summary/Evidences";

export default {
  data() {
    return {
      companyId: "",
      branchID: "",
      sections: ["Ejecución", "Evidencias"],
      activeSection: "",
      submitStart: false,
      showValidate: false
    };
  },
  created() {
    const store = new Storage();
    store.getData("company").then(data => {
      this.companyId = data[0]._id;
    });
    this.activeSection = this.sections[0];
  },
  methods: {
    submit() {
      const planForm = document.getElementById("planForm");
      const select = planForm.getElementsByTagName("select");
      const textarea = planForm.getElementsByTagName("textarea");
      const input = planForm.getElementsByTagName("input");
      let submit = true;

      this.submitStart = !this.submitStart;
      this.showValidate = true;

      for (let i = 0; i < select.length; i++) {
        if (!validateFormData(select[i])) {
          submit = false;
        }
      }
      for (let i = 0; i < textarea.length; i++) {
        if (!validateFormData(textarea[i])) {
          submit = false;
        }
      }
      for (let i = 0; i < input.length; i++) {
        if (!validateFormData(input[i])) {
          submit = false;
        }
      }

      if (submit) {
        this.sendData(planForm);
      }
    },
    sendData(form) {
      const store = new Storage();
      const formData = new FormData(form);
      let phone;

      Swal.fire({
        icon: "warning",
        title: "Ejecutando actividad",
        html:
          "Por favor espere mientras se ejecuta la actividad</b> " +
          '<div class="progress mt-3"><div class="progress-bar" id="progressBranch" role="progressbar" style="width: 0%;" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div></div>',
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        stopKeydownPropagation: true
      });

      let endDate = formData.get("endDate");
      const year = endDate.split("-")[0];
      let month = endDate.split("-")[1] - 1;
      if (month < 10) {
        month = "0" + month.toString();
      }
      let day = endDate.split("-")[2];
      day++;
      if (day < 10) {
        day = "0" + day.toString();
      }
      endDate = new Date(Date.UTC(year, month, day));
      endDate = endDate.getTime();

      const data = {
        _id: this.$route.params._id,
        endDate: endDate,
        startTime: formData.get("startTime"),
        endTime: formData.get("endTime"),
        status: "EJECUTADO"
      };

      store.updateData("summary", [data], true, "optima").then(summaryData => {
        store.getData("file").then(fileList => {
          let parent;
          fileList.forEach(file => {
            if (
              file.name === "Plan de Trabajo y Mejoramiento" &&
              file.branch === this.$route.params.branchId
            ) {
              parent = file._id;
            }
          });
          const fileNames = ["extension1", "extension2", "extension3"];
          const fileData = [];
          let aux = 1;
          fileNames.forEach(fileName => {
            if (formData.get(fileName)) {
              fileData.push({
                expiration: null,
                responsable: null,
                createdBy: localStorage.getItem("user._id"),
                branch: this.$route.params.branchId,
                extension: formData.get("extension" + aux),
                name: "evidencia-" + summaryData[0]._id + " / " + aux,
                parent: parent,
                size: parseInt(formData.get("fileSize" + aux))
              });
            }
            aux++;
          });
          if (fileData.length == 0) {
            this.endSubmit(true);
            return 0;
          }
          store.insertData("optima", "file", fileData).then(data => {
            const progressBranch = document.getElementById("progressBranch");
            let fileLevel;
            const percentUpdate = (loaded, total, percent) => {
              percent -= 5;
              progressBranch.innerText = percent.toString() + "%";
              progressBranch.style.width = percent.toString() + "%";
            };
            data.forEach(file => {
              const newForm = new FormData();
              fileLevel = file.name.substr(file.name.length - 1);
              fileLevel = "evidence" + fileLevel;
              newForm.append("_id", file._id);
              newForm.append("parent", parent);
              console.log(formData.get(fileLevel).name);
              newForm.append(
                "fileInput",
                formData.get(fileLevel),
                formData.get(fileLevel).name
              );
              store
                .uploadFile(
                  "file",
                  file._id,
                  "optima/file/upload",
                  newForm,
                  percentUpdate
                )
                .then(res => {
                  store.getData("summary", summaryData[0]._id).then(summary => {
                    if (!summary.evidences) {
                      summary.evidences = [];
                    }
                    summary.evidences.push(file._id);
                    delete summary.registrationDate;
                    delete summary.lastUpdate;
                    store
                      .updateData("summary", [summary], true, "optima")
                      .then(() => {
                        this.endSubmit(true);
                      });
                  });
                })
                .catch(error => {
                  console.log(error);
                  this.endSubmit(false);
                });
            });
          });
        });
      });

      /*
      store.insertData("optima", "summary", [data]).then(summaryData => {
        store.getData('file').then(fileList => {
          const fileInput = formData.get("fileInput");
          
          if (fileInput.name !== "") {
            let parent;
            fileList.forEach(file => {
              if (file.name === 'Plan de Trabajo y Mejoramiento' && file.branch === this.$route.params._id) {
                parent = file._id;
              }
            });

            const fileData = {
              expiration: null,
              responsable: null,
              createdBy: localStorage.getItem("user._id"),
              branch: this.$route.params._id,
              extension: formData.get("extension"),
              name: formData.get("name"),
              parent: parent,
              size: parseInt(formData.get("fileSize"))
            };
            store
        .insertData("optima", "file", [fileData])
        .then(data => {
          const progressBranch = document.getElementById("progressBranch");
          const percentUpdate = (loaded, total, percent) => {
            percent -= 5;
            progressBranch.innerText = percent.toString() + "%";
            progressBranch.style.width = percent.toString() + "%";
          };

          formData.append("_id", data[0]._id);
          formData.append("parent", parent);
          store
            .uploadFile(
              "file",
              data[0]._id,
              "optima/file/upload",
              formData,
              percentUpdate
            )
            .then(res => {
              store.getData("summary", summaryData[0]._id).then(summary => {
                summary.support = data[0]._id;
                delete summary.registrationDate;
                delete summary.lastUpdate;
                store.updateData("summary", [summary], true, "optima").then(() => {
                  this.endSubmit(true);
                })
              });
            })
            .catch(error => {
              console.log(error);
              this.endSubmit(false);
            });
          })
          .catch(error => {
            console.log(error);
            this.endSubmit(false);
          });
          } else {
            this.endSubmit(true);
          }
        });
      });
      */
    },
    endSubmit(status) {
      if (status) {
        Swal.fire({
          icon: "success",
          title: "Actividad creada",
          text: "Ahora podrá realizar el seguimiento de esta actividad"
        }).then(result => {
          this.$router.go(-1);
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error al crear la actividad",
          text:
            "Los datos han sido guardados en su computador o celular, una vez se restablesca la conexión, los datos se sincronizarán con el servidor"
        }).then(result => {
          this.$router.go(-1);
        });
      }
    },
    moveForm(event) {
      const xScroll = document.getElementsByClassName("xScroll")[0];
      let postion;

      if (event === "forward") {
        for (let i = 0; i < this.sections.length; i++) {
          if (this.activeSection === this.sections[i]) {
            postion = i + 1;
            break;
          }
        }
      } else if (event === "back") {
        for (let i = 0; i < this.sections.length; i++) {
          if (this.activeSection === this.sections[i]) {
            postion = i - 1;
            break;
          }
        }
      } else {
        postion = event;
      }
      this.activeSection = this.sections[postion];
      xScroll.style.marginLeft = postion * 100 * -1 + "%";
    }
  },
  components: {
    passContainer,
    titleForm,
    Legal,
    first,
    second
  },
  name: "Summary Create"
};
</script>

<style lang="css" scoped>
.xScroll {
  margin-left: 0%;
  transition: 0.3s ease-in-out;
}
.mainForm {
  z-index: 8;
  position: absolute;
  width: 100vw;
  overflow-x: hidden;
}
</style>
