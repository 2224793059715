<template>
  <div class="formSection">
    <fieldset class="rounded p-3 card">
      <h5 class="text-center fw-bolder">{{ sectionName }}</h5>
      <div class="mb-3">
        <label for="evidence1" class="form-label">Evidencia #1</label>
        <input
          id="evidence1"
          class="form-control"
          type="file"
          name="evidence1"
          accept=".png,.jpg,.jpeg,.webp"
          @change="checkFile($event.target, 1)"
        />
      </div>
      <div class="mb-3">
        <label for="evidence2" class="form-label">Evidencia #2</label>
        <input
          id="evidence2"
          class="form-control"
          type="file"
          name="evidence2"
          accept=".png,.jpg,.jpeg,.webp"
          @change="checkFile($event.target, 2)"
        />
      </div>
      <div class="mb-3">
        <label for="evidence3" class="form-label">Evidencia #3</label>
        <input
          id="evidence3"
          class="form-control"
          type="file"
          name="evidence3"
          accept=".png,.jpg,.jpeg,.webp"
          @change="checkFile($event.target, 3)"
        />
      </div>
      <input type="hidden" name="extension1" v-model="extension1" />
      <input type="hidden" name="fileSize1" v-model="fileSize1" />
      <input type="hidden" name="extension2" v-model="extension2" />
      <input type="hidden" name="fileSize2" v-model="fileSize2" />
      <input type="hidden" name="extension3" v-model="extension3" />
      <input type="hidden" name="fileSize3" v-model="fileSize3" />
      <div class="d-flex justify-content-between">
        <button
          class="btn btn-secondary shadow-secondary"
          @click.prevent="next('back')"
        >
          Anterior
        </button>
        <button
          class="btn btn-primary shadow-primary"
          @click.prevent="submit()"
        >
          {{ submitName }}
        </button>
      </div>
    </fieldset>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import Storage from "@/modules/storage";

const store = new Storage();

export default {
  props: ["sectionName", "defaultData", "submitName"],
  data() {
    return {
      initDefault: true,
      mounted: false,
      enableExtensions: [],
      extension1: "",
      fileSize1: "",
      extension2: "",
      fileSize2: "",
      extension3: "",
      fileSize3: "",
      evidence1: ""
    };
  },
  mounted() {
    store.getData("schema").then(schemaData => {
      schemaData.forEach(schema => {
        if (schema.store === "file") {
          this.enableExtensions = schema.data.extension.enum;
        }
      });
      this.mounted = true;
    });
  },
  methods: {
    submit() {
      this.$emit("submitForm", true);
    },
    next(event) {
      this.$emit("moveForm", event);
    },
    checkFile(fileInput, level) {
      const file = fileInput.files[0];
      let extension;

      let fileName = file.name.split(".");
      extension = fileName.pop();
      fileName = fileName.join(".");

      extension = extension.toUpperCase();

      const fileSize = file.size;

      if (file.size > 2500000000) {
        Swal.fire({
          icon: "error",
          title: "Archivo no permitido",
          text: "El archivo no debe superar un tamaño de 4GB"
        }).then(() => {
          if (level == 1) {
            document.getElementById("evidence1").value = "";
          } else if (level == 2) {
            document.getElementById("evidence2").value = "";
          } else if (level == 3) {
            document.getElementById("evidence3").value = "";
          }
        });
      }

      if (level == 1) {
        this.extension1 = extension;
        this.fileSize1 = fileSize;
      } else if (level == 2) {
        this.extension2 = extension;
        this.fileSize2 = fileSize;
      } else if (level == 3) {
        this.extension3 = extension;
        this.fileSize3 = fileSize;
      }

      if (!this.enableExtensions.includes(extension)) {
        Swal.fire({
          icon: "error",
          title: "Archivo no permitido",
          text: "No se permite subir archivos con extensión: " + extension
        }).then(() => {
          if (level == 1) {
            document.getElementById("evidence1").value = "";
          } else if (level == 2) {
            document.getElementById("evidence2").value = "";
          } else if (level == 3) {
            document.getElementById("evidence3").value = "";
          }
        });
      }
    }
  },
  watch: {
    defaultData: function(val) {
      if (this.initDefault) {
        const defaultInterval = setInterval(() => {
          if (this.mounted) {
            clearInterval(defaultInterval);
            if (val.support !== undefined && val.support !== null) {
              this.currentSupport = val.support;
            }
          }
        }, 10);
        this.initDefault = false;
      }
    },
    submitValidate: function(val) {
      if (this.errorMsg !== "") {
        this.$emit("moveForm", this.sectionPosition);
      }
    }
  },
  name: "Summary Execute Form 2"
};
</script>

<style lang="css" scoped>
.formSection {
  min-width: 100vw;
}
.formSection fieldset {
  width: 350px;
  background-color: var(--secondary-bg);
  margin: auto;
}
</style>
